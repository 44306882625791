import React, { useContext, useEffect, useState, useMemo } from "react";

// Models
import { PopUpType } from "../../Models/Enums/PopUpType";
import IPartnerDTO from "../../Models/DTOs/IPartnerDTO";
import IArticleCategoryListDTO from "../../Models/DTOs/IArticleCategoryListDTO";
import IArticleCategoryLatestDTO from "../../Models/DTOs/IArticleCategoryLatestDTO";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// Helpers
import { SetTitle } from "../../Helpers/PageMetadata";
import { IsAuthenticated } from "../../Helpers/Account";

// API
import { GetCategory } from "../../Api/Article";

// Components
import ArticlesGrid from "../../Components/Layouts/Content/Articles/ArticlesGrid";
import ApiPromoPopUp from "../../Components/Popups/ApiPromoPopUp";

// Hooks
import useFetchArticleCategories from "../../Hooks/Content/Read/useFetchArticleCategories";
import useFetchRandomPartner from "../../Hooks/Partners/useFetchRandomPartner";

function ArticlesScreen() {
    const
        // Context
        { userData, doesAuthTokenExist } = useContext(UserAuthenticationContext),

        // Hooks
        { categories, categoriesLoading, categoriesError } = useFetchArticleCategories(0, 20, true),
        { partner } = useFetchRandomPartner(!doesAuthTokenExist()),

        // State
        [ categoriesWithFullArticles, setCategoriesWithFullArticles ] = useState<IArticleCategoryListDTO[]>([]),
        [ isLoading, setIsLoading ] = useState(true);

    // Set page title on mount
    useEffect(() => {
        SetTitle("News");
    }, []);

    // Fetch full articles for each category
    useEffect(() => {
        const controller = new AbortController();

        async function enrichCategories() {
            if (!categories || categories.length === 0) return;

            setIsLoading(true);

            try {
                const fetchPromises = categories.map(async (cat) => {
                    const response = await GetCategory(cat.CategoryId, 10, controller);
                    const fullCategory = response as IArticleCategoryLatestDTO;

                    return {
                        CategoryId: fullCategory.Category.Id,
                        Title: cat.Title,
                        Articles: fullCategory.Latest,
                        Partner: cat.Partner
                    };
                });

                const enriched = await Promise.all(fetchPromises);

                setCategoriesWithFullArticles(enriched);
            } catch (err) {
                console.error("Error enriching categories with full articles", err);
            } finally {
                setIsLoading(false);
            }

            return () => controller.abort();
        }

        enrichCategories();
    }, [categories]);

    // Memoize the article groups to avoid re-renders
    const memoizedArticleGroups = useMemo(() => {
        return categoriesWithFullArticles;
    }, [categoriesWithFullArticles]);

    return (
        <div className="page page--read">
            <h1>Latest Articles</h1>

            {!IsAuthenticated(userData) && (
                <ApiPromoPopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Read}
                    isFullScreen={true}
                />
            )}

            <ArticlesGrid
                articleGroups={memoizedArticleGroups}
                disableLoadMore={true}
                isLoading={isLoading || categoriesLoading}
            />
        </div>
    );
}

export default ArticlesScreen;
