import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

// Models
import ICarouselContentDTO, { createPlaceholderICarouselContentDTO } from "../../../../Models/DTOs/ICarouselContentDTO";

// Components
import ButtonPill from "../../../Buttons/ButtonPill";

// Assets
import IconArrowCarrot from "../../../../Assets/SVGs/Icons/Arrows/ArrowCarrot";

// Constants
import {RoutePaths} from "../../../../Constants/RoutePaths";

interface StyledButtonProps {
    buttonColor: string;
    buttonColorHover: string;
}

const StyledButton = styled(ButtonPill)<StyledButtonProps>`
    background-color: rgb(${({ buttonColor }) => buttonColor});
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
        background-color: rgb(${({ buttonColorHover }) => buttonColorHover});
    }
`;

type RGB = `${number},${number},${number}`;

type CarouselCardsWithContentProps = {
    isDarkMode: boolean;
    heading: string;
    body: React.ReactNode;
    contentLeftSide: boolean;
    buttonText: string,
    buttonLink: string;
    buttonTheme: boolean;
    buttonColor: RGB;
    buttonColorHover: RGB;
    focusImage: string;
    showFocusImageOnMobile: boolean;
    bgImage?: string;
    bgColor: string;
    contentItems?: ICarouselContentDTO[];
};

const CategoryFeaturedItems: React.FC<CarouselCardsWithContentProps> = (
    {
        isDarkMode,
        heading,
        body,
        contentLeftSide,
        buttonText,
        buttonLink,
        buttonTheme,
        buttonColor,
        buttonColorHover,
        focusImage,
        showFocusImageOnMobile,
        bgImage,
        bgColor,
        contentItems
    }
) => {
    const
        isLoading = !contentItems || contentItems.length === 0,

        placeholderItems = createPlaceholderICarouselContentDTO(4),

        // Once we have data, use it; otherwise use placeholders
        items = isLoading || contentItems.length === 0 ? placeholderItems : contentItems;

    return (
        <div
            className={`layout--category-featured-items u-relative_hidden
                ${isDarkMode ? "is-dark-mode" : "has-light-mode"}
                ${contentLeftSide ? "has-content-left" : "has-content-right"}
            `}
            style={{background: bgColor}}
        >
            <div className="category-featured-items__inner">
                <div className="category-featured-items__content u-relative_hidden">
                    {heading && (
                        <h1 style={{color: `rgb(${buttonColor})`}}>
                            {heading}
                        </h1>
                    )}

                    {body && body}

                    <StyledButton
                        label={buttonText}
                        className={buttonTheme ? 'has-light-theme' : 'has-dark-theme'}
                        link={buttonLink}
                        icon={IconArrowCarrot({iconFill: buttonTheme ? "white" : "black"})}
                        buttonColor={buttonColor}
                        buttonColorHover={buttonColorHover}
                    />
                </div>

                {bgImage && (
                    <div className="category-featured-items__bg-image u-full_cover_absolute"
                         style={{background: `url("${bgImage}") center/cover no-repeat`}}/>
                )}

                <div
                    className={`category-featured-items__focus-image ${showFocusImageOnMobile ? 'show-on-mobile' : 'dont-show-on-mobile'} u-full_cover_absolute`}
                    style={{background: `url("${focusImage}") top ${contentLeftSide ? 'right' : 'left'}/contain no-repeat`}}/>

                <div className="category-featured-items__wrapper">

                    {items.map((item, i) => (
                        <div
                            key={i}
                            className="category-featured-items__item u-relative_hidden"
                            style={{boxShadow: `0 0 25px 0 rgba(${buttonColor},0.4)`}}
                        >
                            {item.IsFreeView &&
                                <div className="category-featured-items__item-free-view free-view-content-item-label">
                                    <span>Watch Free</span></div>
                            }

                            <NavLink to={item.Id ? (item.Text.includes("Series") ? RoutePaths.SeriesEpisodes(item.Id) : RoutePaths.Watch(item.Id)) : "#"}
                                     className="category-featured-items__item-image is-sixteen-nine-aspect-ratio"
                            >
                                {isLoading ?
                                    (
                                        <div className="loader--side-to-side"/>
                                    )
                                    :
                                    (
                                        <div
                                            className="u-full_cover_absolute"
                                            style={{
                                                background: `url("${item.Thumbnail ?? ""}") center/cover no-repeat`,
                                            }}
                                        />
                                    )
                                }

                                <div className="category-featured-items__item-image-gradient"/>
                            </NavLink>

                            <div className="category-featured-items__item-content">
                                <h3>
                                    <NavLink to={item.Id ? (item.Text.includes("Series") ? RoutePaths.SeriesEpisodes(item.Id) : RoutePaths.Watch(item.Id)) : "#"}>{item.Title}</NavLink>
                                </h3>

                                <NavLink
                                    to={item.Id ? (item.Text.includes("Series") ? RoutePaths.SeriesEpisodes(item.Id) : RoutePaths.Watch(item.Id)) : "#"}>{IconArrowCarrot({iconFill: "white"})}</NavLink>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="category-featured-items__gradient-overlay-top u-full_cover_absolute"
                    style={{background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${bgColor} 100%)`}}
                />

                <div
                    className="category-featured-items__gradient-overlay-bottom u-full_cover_absolute"
                    style={{background: `linear-gradient(0deg, ${bgColor} 0%, rgba(0,0,0,0) 100%)`}}
                />
            </div>
        </div>
    );
};

export default CategoryFeaturedItems;
