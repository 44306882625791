import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

import { Fonts } from "Constants/Fonts";
import { Colours } from "Constants/Colours";
import IArticleReadDTO from "Models/DTOs/IArticleReadDTO";
import { HeadingType } from "Models/Enums/HeadingType";
import { PopUpType } from "Models/Enums/PopUpType";
import { ContentType } from "Models/Enums/ContentType";

import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";
import {IsAuthenticated} from "../../Helpers/Account";
import {isValidId} from "../../Helpers/Numbers";
import {SetTitle} from "../../Helpers/PageMetadata";

import { Read, ReadByTitle } from "Api/Article";
import SharingMetatags from "Components/Headers/SharingMetatags";
import Heading from "Components/UI/Text/Heading";
import PopUp from "Components/UI/Modals/PopUp";
import HeadingLoader from "Components/UI/PageLoaders/HeadingLoader";
import VideoSingleLoader from "Components/UI/PageLoaders/VideoSingleLoader";
import ArticleGridItemSingle from "Components/UI/Article/ArticleGridItemSingle";
import { NextAricle } from "Components/UI/Article/NextArticle";
import ArticleHeader from "Components/UI/Article/ArticleHeader";
import { Comments } from "Components/UI/Article/Comments";
import { TagsList } from "Components/UI/Article/TagList";
import { CategoryTerm } from "Components/UI/Article/CategoryTerm";
import { SocialsMedia } from "Components/UI/SocialsMedia";
import {SignUpCard} from "../../Components/UI/SignUpCard";
import BannerAd from "Components/UI/Ads/BannerAd";

const Container = styled.section`
    background: ${Colours.TextBright};

    .loader--side-to-side {
      background: ${Colours.Text};
      ::after {
        background: linear-gradient(
          90deg,
          ${Colours.Transparent} 20%,
          ${Colours.ReplyThread} 50%,
          ${Colours.Transparent} 80%
        );
      }
    }
  `,
    ArticleContent = styled.div`
    max-width: 990px;
    padding: 0 20px;
    margin: 0 auto;

    .loader--side-to-side {
      width: 100%;
    }

    .article-content {
      p,
      li {
        margin-bottom: 32px;
        color: ${Colours.TextBlack};
        font-family: ${Fonts.Primary};
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.1px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 26px;
        }

        @media screen and (max-width: 576px) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }

      &__ad-conatiner {
        margin-bottom: 20px;
      }

      a {
        word-break: break-word;
        color: ${Colours.IckonicPink};
        text-decoration: none;
      }

      &__meta-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding: 16px;
        flex-wrap: wrap;

        @media screen and (max-width: 992px) {
          padding: 12px 0;
          flex-direction: column;
        }
      }
    }
  `,
    ArticleHighlights = styled.div`
    padding: 120px 0;

    @media screen and (max-width: 1024px) {
      padding: 90px 0;
    }

    @media screen and (max-width: 768px) {
      padding: 60px 0;
    }

    @media screen and (max-width: 576px) {
      padding: 40px 0;
    }

    .article-highlights__section {
      max-width: 1480px;
      margin: 0 auto;
      padding: 0 20px;
      display: grid;
      gap: 120px;

      @media screen and (max-width: 768px) {
        gap: 80px;
      }

      @media screen and (max-width: 576px) {
        gap: 40px;
      }
    }
  `;

const ArticlesGrid = styled.div`
    display: grid;
    gap: 40px;

    .suggested-article {
      &__header {
        display: flex;
        align-items: center;
        gap: 16px;

        @media screen and (max-width: 576px) {
          flex-direction: column;
          gap: 4px;
        }

        h3 {
          margin: 0;
          font-family: ${Fonts.Rubik};
          color: ${Colours.TextBlack};
          font-weight: 500;
          font-size: 40px;
          line-height: 48px;

          @media screen and (max-width: 768px) {
            font-size: 26px;
            line-height: 34px;
          }

          @media screen and (max-width: 576px) {
            font-size: 20px;
            line-height: 30px;
          }
        }

        a {
          padding: 5px 11px;
          font-weight: 600;
          letter-spacing: 0;
          color: ${Colours.Text};
          text-transform: uppercase;
          flex-shrink: 0;

          @media screen and (max-width: 576px) {
            font-size: 10px;
            line-height: 14px;
            margin-left: auto;
          }
        }
      }

      &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 32px;

        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
        }

        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }

        .article-single {
          width: 100%;
          margin: 0;

          img {
            aspect-ratio: 16 / 9;
            object-fit: cover;
          }
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
        }

        h3 {
          margin-top: 6px;
          font-size: 0;

          @media screen and (max-width: 768px) {
            margin-top: 4px;
          }

          a {
            display: inline-block;
            color: ${Colours.TextBlack};
            font-family: ${Fonts.Rubik};
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;

            @media screen and (max-width: 768px) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  `,
    DummyHeader = styled.div`
    margin-top: 40px !important;
    height: 500px;
    width: 100%;
    margin: 0 auto;
    max-width: 950px;
    position: relative;
    overflow: hidden;
  `;

const ArticleDetailsScreen = () => {
    const [article, setArticle] = useState<IArticleReadDTO | null>(null);
    const [canFetch, setCanFetch] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeArticleId, setActiveArticleId] = useState<
        string | number | null
    >(null);

    const controller = new AbortController();
    const { id } = useParams();
    const authCtx = useContext(UserAuthenticationContext);
    const commentsRef = useRef<HTMLDivElement>(null);

    const GetArticle = async () => {
        const checkById = typeof id === "string" ? isValidId(id) : false;
        let result;

        if (checkById) {
            result = typeof id === "string" ? Read(parseInt(id), controller) : null;
        } else {
            result = typeof id === "string" ? ReadByTitle(id, controller) : null;
        }

        return result;
    };

    const fetchArticleData = async () => {
        const response = (await GetArticle()) as IArticleReadDTO;

        if (typeof id === "string") {
            setActiveArticleId(id);
        }

        console.log(response);

        SetTitle(response?.Article?.Title);
        setArticle(response);
        setIsLoading(false);
    };

    useEffect(() => {
        return () => {
            setCanFetch(true);
            setArticle(null);
        };
    }, []);

    useEffect(() => {
        if (activeArticleId === null || id !== activeArticleId) {
            setCanFetch(true);
            setIsLoading(true);
        }
    }, [id, activeArticleId]);

    useEffect(() => {
        if (canFetch) {
            setCanFetch(false);
            fetchArticleData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canFetch]);

    return (
        <Container>
            {!IsAuthenticated(authCtx.userData) && (
                <PopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Read}
                    isFullScreen={true}
                />
            )}

            {!isLoading && article !== null ? (
                <>
                    {/* Meta Tags */}
                    <SharingMetatags
                        title={article.Article.Title}
                        description={article.Article.SubTitle}
                        url={article.Article.UrlTitle}
                        image={article.Article.Header}
                        type="article"
                    />

                    {/* Article Header  */}
                    <ArticleHeader
                        article={article?.Article}
                        ReadTime={article?.ReadTime}
                        DateToString={article?.DateToString}
                        commentsRef={commentsRef}
                    />

                    {/* Article Content  */}
                    <ArticleContent>
                        <div className="article-content">
                            {article?.Text &&
                                article?.Text?.length > 0 &&
                                article?.Text?.map((text, i) => (
                                    <>
                                        {/*/!* TODO Check for external ad*!/*/}
                                        {/*/!* Ickonic Ad  *!/*/}
                                        {/*{article?.IckonicAd &&*/}
                                        {/*    Math.floor(article?.Text?.length / 2) === i && (*/}
                                        {/*        <div className="article-content__ad-conatiner">*/}
                                        {/*            <BannerAd partner={article?.IckonicAd} />*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}

                                        <Fragment key={i}>
                                            {parse(DOMPurify.sanitize(text))}
                                        </Fragment>
                                    </>
                                ))}
                        </div>

                        <div className="article-content__meta-container">
                            {/* Static tag list */}
                            {/*<TagsList />*/}

                            <SocialsMedia
                                isShare={true}
                                title={article?.Article?.Title}
                                contentType={ContentType.Article}
                                articleUrlTitle={article?.Article?.UrlTitle}
                            />
                        </div>

                        {/* Static card - will change based on user session */}
                        {/*<SignUpCard />*/}

                        {/* Static comments section */}
                        {/*<div ref={commentsRef}>*/}
                        {/*    <Comments />*/}
                        {/*</div>*/}
                    </ArticleContent>

                    {/* Article Highlights - Next Article, Suggested Articles*/}
                    <ArticleHighlights>
                        <div className="article-highlights__section">
                            <NextAricle article={article?.NextArticle} />

                            <ArticlesGrid>
                                {article?.SuggestedArticles &&
                                    article?.SuggestedArticles?.length > 0 && (
                                        <>
                                            <div className="suggested-article__header">
                                                <Heading type={HeadingType.H3}>
                                                    Latest in{" "}
                                                    {article?.Article?.Categories?.[0]?.Title ||
                                                        "Global Current Events"}
                                                </Heading>

                                                <CategoryTerm
                                                    category={{
                                                        ...(article?.Article?.Categories?.[0] ?? {
                                                            Id: 1,
                                                            Position: 0,
                                                        }),
                                                        Title: "View All",
                                                    }}
                                                />
                                            </div>

                                            <div className="suggested-article__container">
                                                {article?.SuggestedArticles?.map((article) => (
                                                    <ArticleGridItemSingle
                                                        article={article}
                                                        key={article?.Id}
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                            </ArticlesGrid>
                        </div>
                    </ArticleHighlights>
                </>
            ) : (
                <>
                    <DummyHeader>
                        <div className="loader--side-to-side" />
                    </DummyHeader>

                    <ArticleContent>
                        {Array.from({ length: 32 }, (_, i) => i).map((_, index) => (
                            <HeadingLoader
                                paddingTop={index === 0 ? "0" : "16"}
                                key={index}
                            />
                        ))}
                    </ArticleContent>

                    <ArticleHighlights>
                        <div className="article-highlights__section">
                            <ArticlesGrid>
                                {Array.from({ length: 4 }, (_, i) => i).map((_, index) => (
                                    <VideoSingleLoader
                                        paddingTop={index === 0 ? "0" : "16"}
                                        key={index}
                                    />
                                ))}
                            </ArticlesGrid>
                        </div>
                    </ArticleHighlights>
                </>
            )}
        </Container>
    );
};

export default ArticleDetailsScreen;
