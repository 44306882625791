import { useState, useEffect } from "react";

// API
import { GetPlansAPI } from "../../Api/Subscription";

// Models
import IPlanDTO from "../../Models/DTOs/IPlanDTO";

function useFetchSubscriptionPlans(userId?: string) {
    const
        [ plans, setPlans ] = useState<IPlanDTO[] | null>(null),
        [ plansLoading, setPlansLoading ] = useState<boolean>(false),
        [ plansError, setPlansError ] = useState<string>("");

    useEffect(() => {

        let isCancelled = false;
        const abortController = new AbortController();

        setPlansLoading(true);

        (async () => {
            try {
                const data = await GetPlansAPI(abortController, userId);

                if (!isCancelled && Array.isArray(data)) {
                    setPlans(data);
                } else if (!isCancelled) {
                    throw new Error("Invalid response format.");
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error(err);
                    setPlansError("Failed to fetch subscription plans.");
                }
            } finally {
                if (!isCancelled) {
                    setPlansLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [userId]);

    return { plans, plansLoading, plansError };
}

export default useFetchSubscriptionPlans;
