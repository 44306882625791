import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import lscache from "lscache";

// Hooks
import useFetchVideo from "../../Hooks/Content/Watch/useFetchVideoById";
import useFetchVideoComments from "../../Hooks/Comments/useFetchVideoComments";
import useFetchVideoPeakInfo from "../../Hooks/VideoInfo/useFetchVideoPeakInfo";

// Components
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import VideoPrimaryInformation from "../../Components/VideoInfo/VideoPrimaryInformation";
import VideoRelatedVideos from "../../Components/VideoInfo/VideoRelatedVideos";
import VideoComments from "../../Components/VideoComments";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { VideoPlayerSettingsContext } from "../../Context/VideoPlayerSettingsContext";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import VideoAccessOverlay from "../../Components/VideoInfo/VideoAccessOverlay";

function WatchVideo() {
    const

        // Parse the URL param into a number.
        { id } = useParams<string>(),
        videoId = id ? parseInt(id) : undefined,

        // Context
        { userData, doesAuthTokenExist } = useContext(UserAuthenticationContext),

        // Theatre mode forces the player to fill the width of the browser
        { isTheatreMode, setIsTheatreMode } = useContext(VideoPlayerSettingsContext),

        // State
        [ videoData, setVideoData ] = useState<IVideoDTO>({} as IVideoDTO),
        [ refreshComments, setRefreshComments ] = useState(0),
        [ isCommentInputFocused, setIsCommentInputFocused ] = useState(false),

        // Determine if the video is allowed to play
        [ playbackAllowed, setPlaybackAllowed] = useState(false),

        // Hooks
        { video } = useFetchVideo(videoId, "GB"),
        { comments, loading, error } = useFetchVideoComments(videoData.Id, refreshComments),
        { peak, loading: peakLoading, error: peakError } = useFetchVideoPeakInfo(videoData.Id);

    useEffect(() => {
        if (video) {
            if (videoData.Id === undefined || video.Id !== videoData.Id) {
                setPlaybackAllowed(false);
                setVideoData(video);
            }

            if (!doesAuthTokenExist()) {
                if (video.IsFreeView) {
                    const hasPerformedAction = lscache.get("freeViewAllowed");
                    setPlaybackAllowed(hasPerformedAction);
                }
            }
        }
    }, [video, userData]);

    return (
        <div className="page--watch-video">
            <div className={`page__wrapper ${isTheatreMode ? 'is-theatre-mode' : 'is-not-theatre-mode'}`}>
                <VideoPlayer
                    key={videoData.Id}
                    video={videoData}
                    autoPlay={playbackAllowed}
                    playbackAllowed={playbackAllowed}
                    isTheatreMode={isTheatreMode}
                    setIsTheatreMode={setIsTheatreMode}
                    peak={peak}
                    isCommentInputFocused={isCommentInputFocused}
                />

                <div className="watch__column watch__column-info">
                    <VideoPrimaryInformation
                        video={videoData}
                        isVideoLoading={videoData.Id === undefined}
                        doesAuthTokenExist={doesAuthTokenExist()}
                        userData={userData}
                    />

                    <VideoComments
                        videoId={videoData.Id}
                        comments={comments}
                        disable={!userData.AspNetUserId}
                        UpdateComments={() => setRefreshComments(prev => prev + 1)}
                    />
                </div>

                <div className="watch__column watch__column-related">
                    <VideoRelatedVideos
                        videoId={videoData.Id}
                        videoCategoryId={videoData.VideoCategoriesId}
                        additionalCategories={videoData.AdditionalCategories}
                        parentContentStillLoading={videoData.Id === undefined}
                    />
                </div>
            </div>

            {/* Show the overlay if playback is not yet allowed */}
            {!playbackAllowed && (
                <VideoAccessOverlay
                    video={videoData}
                    userData={userData}
                    doesTokenExist={doesAuthTokenExist()}
                    playbackAllowed={playbackAllowed}
                    onAccessGranted={() => {
                        // When the user performs the required action:
                        setPlaybackAllowed(true);
                        if (videoData.IsFreeView) {
                            lscache.set("freeViewAllowed", true)
                        }
                    }}
                />
            )}
        </div>
    );
}

export default WatchVideo;
