import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { InputIsValid } from "../../../Models/Enums/InputIsValid";
import { InputState } from "../../../Models/Enums/InputState";
import IInputDTO from "../../../Models/DTOs/IInputDTO";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { Colours } from "../../../Constants/Colours";
import { Ease } from "../../../Constants/EasingCurves";
import PrimaryText from "../../../Components/UI/Text/PrimaryText";
import Heading from "../../../Components/UI/Text/Heading";
import CheckBoxWithLabel from "../../../Components/UI/Inputs/CheckBoxWithLabel";
import NavLinkPinkTextButton from "../../../Components/UI/Buttons/NavLinkPinkTextButton";
import InputWithIcon from "../../../Components/UI/Inputs/InputWithIcon";
import WhiteButton from "../../../Components/UI/Buttons/WhiteButton";
import InputWithLabel from "../../../Components/UI/Inputs/InputWithLabel";
import Card from "../../../Components/UI/Card";
import IconLoaderCircle from "../../../Assets/SVGs/Icons/Loading/LoaderCircle";
import IconCheckMark from "../../../Assets/SVGs/Icons/YesOrNo/CheckMark";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { IckonicLogo } from "../../../Assets/SVGs/Logos";
import { LoginAPI } from "../../../Api/Account";
import { ILoginDTO } from "../../../Models/DTOs/ILoginDTO";
import { SetTitle } from "../../../Helpers/PageMetadata";
import { ContainsAt } from "../../../Helpers/Strings";
import ErrorText from "../../../Components/UI/Text/ErrorText";

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    margin-top: 60px;
    * {
        box-sizing: border-box;
    }

    a {
        font-size: 12pt;
        color: ${Colours.IckonicPinkHighlight};
    }

    > div {
        max-width: 600px;
    }

    input::placeholder {
        color: ${Colours.LighterGrey};
    }

    .form__submit {
        min-height: 38px;
        padding: 12px;
        position: relative;
        margin: 13px 0 22px 0;
        width: 100%;
        display: block;
        position: relative;
        transition: width 0.25s ${Ease.Smooth}, left 0.25s ${Ease.Smooth},
        border-radius 0s linear 0.25s;
        left: 0;

        &.has-success {
            position: relative;
            background: ${Colours.IckonicPink};
            box-shadow: 0 0 0 calc(2rem/16) ${Colours.IckonicPink};
            width: 38px !important;
            height: 38px;
            left: calc(50% - (19rem/16));

            .button__icon {
                border-radius: 50%;
                animation-name: pulseHuge;
                animation-duration: 700ms;
                animation-timing-function: ${Ease.LateSnap};
            }
        }

        .button__icon {
            width: 25px;
            height: 25px;
            margin: 0 auto;
            position: absolute;
            top: calc(50% - (13rem/16));
            left: calc(50% - (13rem/16));
            * {
                fill: black;
            }

            &.icon--loading {
                animation-name: spin;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;
                animation-timing-function: ${Ease.Smooth};
                * {
                    fill: white;
                }
            }
        }
    }

    > div {
        margin: 75px 0 35px 0;
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}em/16)) {
        padding: 0;

        > div {
            transform: translateY(0);
            background: rgba(10,10,10,1);
        }
    }
`;

const TextContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;

    p {
        width: calc(50% - 8px);
        text-align: right;
        margin: 0 0 0 auto;
        max-width: 120px;
    }

    h1 {
        margin: 0;
        width: calc(50% - 8px);
        font-size: 22pt;
    }

    @media screen and (min-width: calc(350em/16)) {
        p {
            width: calc(60% - 8px);
            max-width: none;
        }

        h1 {
            width: calc(40% - 8px);
        }
    }
`;

const LoginForm = styled.form`
    a {
        color: ${Colours.TertiaryHighlight};
    }
`;

const OptionsContainer = styled.div`
    margin: 1rem 0 0 0;
`;

const LogoContainer = styled(NavLink)`
    position: absolute;
    top: 16px;
    left: calc(50% - (55rem/16));
    width: 110px;
    height: 30px;
    svg {
        & > circle {
            stroke: ${Colours.Text};
            transition: stroke 0.3s ${Ease.Smooth};
        }
    }

    g {
        * {
            fill: ${Colours.Text};
            transition: fill 0.3s ${Ease.Smooth};
        }
    }

    &:hover,
    &:focus {
        svg {
            & > circle {
                stroke: ${Colours.IckonicPinkHighlight};
            }
        }

        g {
            * {
                fill: ${Colours.IckonicPinkHighlight};
            }
        }
    }
`;

const LoginScreen = () => {
    // Use useSearchParams to read ?redirectTo= from the query string
    const [searchParams] = useSearchParams();
    const redirectQuery = searchParams.get("redirectTo"); // e.g. "Roku"

    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();
    const emailInputRef = useRef<HTMLInputElement | null>(null);
    const passwordInputRef = useRef<HTMLInputElement | null>(null);

    const [formIsValid, setFormIsValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [seePassword, setSeePassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Redux-like input states
    const emailReducer = (state: IInputDTO, action: IInputDTO) => {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: getEmailKeyUpState(state.Value),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: getEmailValidState(state.Value),
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    };

    const passwordReducer = (state: IInputDTO, action: IInputDTO) => {
        switch (action.Type) {
            case InputState.User_Input:
                return {
                    Value: action.Value,
                    IsValid: getPasswordKeyUpState(action.Value.trim()),
                } as IInputDTO;
            case InputState.Input_Blur:
                return {
                    Value: state.Value,
                    IsValid: getPasswordValidState(state.Value.trim()),
                } as IInputDTO;
            default:
                return { Value: "", IsValid: InputIsValid.NotSet } as IInputDTO;
        }
    };

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        Value: "",
        IsValid: InputIsValid.NotSet,
    } as IInputDTO);

    const emailIsValid =
        emailState.IsValid === InputIsValid.Valid ||
        emailState.IsValid === InputIsValid.NotSet;

    const passwordIsValid =
        passwordState.IsValid === InputIsValid.Valid ||
        passwordState.IsValid === InputIsValid.NotSet;

    // Validation Helpers
    function getEmailValidState(text: string): InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setEmailError("E-mail cannot be empty");
            return InputIsValid.Invalid;
        }

        if (!ContainsAt(text)) {
            setEmailError("E-mail is not valid");
            return InputIsValid.Invalid;
        }

        setEmailError(undefined);
        return InputIsValid.Valid;
    }

    function getEmailKeyUpState(text: string): InputIsValid {
        if (!emailError) {
            return InputIsValid.Valid;
        }
        return getEmailValidState(text);
    }

    function getPasswordKeyUpState(text: string): InputIsValid {
        if (!passwordError) {
            return InputIsValid.Valid;
        }
        return getPasswordValidState(text);
    }

    function getPasswordValidState(text: string): InputIsValid {
        if (text.length <= 0 || text.trim().length <= 0) {
            setPasswordError("Password cannot be empty");
            return InputIsValid.Invalid;
        }

        if (text.length < 8) {
            setPasswordError("Password must be at least 8 characters");
            return InputIsValid.Invalid;
        }

        setPasswordError(undefined);
        return InputIsValid.Valid;
    }

    function emailChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatchEmail({
            Type: InputState.User_Input,
            Value: e.target.value,
        } as IInputDTO);
    }

    function passwordChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatchPassword({
            Type: InputState.User_Input,
            Value: e.target.value,
        } as IInputDTO);
    }

    function validateEmailHandler() {
        dispatchEmail({ Type: InputState.Input_Blur } as IInputDTO);
    }

    function validatePasswordHandler() {
        dispatchPassword({ Type: InputState.Input_Blur } as IInputDTO);
    }

    // Called when "Sign In" is clicked
    async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const abortController = new AbortController();
            const tokenApiResponse = await LoginAPI(
                emailState.Value,
                passwordState.Value,
                abortController
            );
            const tokenData = tokenApiResponse as ILoginDTO;

            if (tokenData.access_token) {
                authCtx.storeToken(tokenData.access_token, rememberMe);
                const success = authCtx.fetchUserData(tokenData.access_token);

                if (success !== null) {
                    // If redirectTo query param is found, prepend a slash. Otherwise, fall back to Browse.
                    const destination = redirectQuery ? `/${redirectQuery}` : RoutePaths.Browse;

                    setTimeout(() => {
                        console.log("Successful login. Redirecting to:", destination);
                        navigate(destination);
                    }, 1500);
                } else {
                    setError("User data fetch failed");
                }
            } else {
                setError("Login failed. Please check your credentials.");
            }
        } catch (err) {
            setError("An error occurred during login. Please try again.");
        } finally {
            setLoading(false);
        }
    }

    // Toggle Password Visibility
    function togglePassword() {
        setSeePassword(!seePassword);
    }

    // Check form validity after every input
    useEffect(() => {
        const timer = setTimeout(() => {
            setFormIsValid(emailIsValid && passwordIsValid);
        }, 500);

        return () => clearTimeout(timer);
    }, [emailIsValid, passwordIsValid]);

    // On mount
    useEffect(() => {
        SetTitle("Login");
    }, []);

    return (
        <Container>
            <LogoContainer to="/">
                {IckonicLogo()}
            </LogoContainer>

            <Card>
                <TextContainer>
                    <Heading type={HeadingType.H1}>Sign in</Heading>
                    <PrimaryText>
                        Not A Member?{" "}
                        <NavLinkPinkTextButton to={RoutePaths.CreateYourAccount}>
                            Sign Up!
                        </NavLinkPinkTextButton>
                    </PrimaryText>
                </TextContainer>

                <LoginForm onSubmit={submitHandler}>
                    <InputWithLabel
                        ref={emailInputRef}
                        id="email"
                        type="email"
                        label="Email Address"
                        placeholder="you@example.com"
                        value={emailState.Value}
                        isValid={
                            (success && emailError === undefined) ||
                            (emailIsValid && !success && emailError === undefined)
                        }
                        onChange={emailChangeHandler}
                        onBlur={validateEmailHandler}
                        errorMessage={emailError}
                    />

                    <InputWithIcon
                        ref={passwordInputRef}
                        label="Password"
                        placeholder="******"
                        type={seePassword ? "text" : "password"}
                        isValid={passwordIsValid || passwordState.IsValid === InputIsValid.NotSet}
                        value={passwordState.Value}
                        onChange={passwordChangeHandler}
                        onBlur={validatePasswordHandler}
                        onIconClick={togglePassword}
                        errorMessage={passwordError}
                    >
                        {seePassword ? <FaEye /> : <FaEyeSlash />}
                    </InputWithIcon>

                    <NavLinkPinkTextButton to={RoutePaths.ForgotPassword}>
                        Forgot Password?
                    </NavLinkPinkTextButton>

                    <WhiteButton
                        className={`form__submit ${success ? "has-success" : "is-waiting-for-login"}`}
                        disabled={
                            !formIsValid ||
                            passwordState.IsValid === InputIsValid.NotSet ||
                            emailState.IsValid === InputIsValid.NotSet ||
                            loading
                        }
                    >
                        {success ? (
                            <div className="button__icon icon--success">{IconCheckMark()}</div>
                        ) : loading ? (
                            <div className="button__icon icon--loading">{IconLoaderCircle()}</div>
                        ) : (
                            "Sign In"
                        )}
                    </WhiteButton>

                    { error && (
                        <ErrorText>
                            { error }
                        </ErrorText>
                    ) }

                    <OptionsContainer>
                        <CheckBoxWithLabel
                            text="Remember Me"
                            checked={rememberMe}
                            setChecked={setRememberMe}
                        />
                    </OptionsContainer>
                </LoginForm>
            </Card>
        </Container>
    );
};

export default LoginScreen;
