import React, {useContext, useEffect, useState, useMemo} from "react";
import { useParams } from "react-router-dom";

// Components
import ArticlesGrid from "../../Components/Layouts/Content/Articles/ArticlesGrid";
import ApiPromoPopUp from "../../Components/Popups/ApiPromoPopUp";

// Context
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Helpers
import {SetTitle} from "../../Helpers/PageMetadata";
import {IsAuthenticated} from "../../Helpers/Account";

// Hooks
import useFetchArticlesByCategory from "../../Hooks/Content/Read/useFetchArticlesByCategory";

// Models
import IArticleCategoryListDTO from "../../Models/DTOs/IArticleCategoryListDTO";
import {PopUpType} from "../../Models/Enums/PopUpType";
import useFetchArticleCategoryInfo from "../../Hooks/Content/Read/useFetchArticleCategoryInfo";
import ButtonPill from "../../Components/Buttons/ButtonPill";
import {RoutePaths} from "../../Constants/RoutePaths";

function ArticleCategoryScreen() {
    const
        { userData, doesAuthTokenExist } = useContext(UserAuthenticationContext),

        params = useParams(),
        categoryId = Number(params.id),

        // State
        [ skip, setSkip ] = useState(0),
        [ articleGroups, setArticleGroups ] = useState<IArticleCategoryListDTO[]>([]),

        // Static Vars
        articleTake = 30,
        articlesPerGroup = 10,

        // Hooks
        { categoryArticles, loading, error } = useFetchArticlesByCategory(categoryId, skip, articleTake, 0),
        { categoryInfo } = useFetchArticleCategoryInfo(categoryId, 1),

        // Functions
        handleLoadMore = () => {
            setSkip(prev => prev + articleTake);
        };

    useEffect(() => {
        if (categoryInfo?.Category.Title) {
            SetTitle(categoryInfo?.Category.Title);
        }
    }, [categoryInfo]);

    useEffect(() => {
        if (categoryArticles?.Articles && categoryArticles.Articles.length > 0) {
            const grouped: IArticleCategoryListDTO[] = [];

            for (let i = 0; i < categoryArticles.Articles.length; i += articlesPerGroup) {
                grouped.push({
                    CategoryId: categoryId,
                    Title: "",
                    Articles: categoryArticles.Articles.slice(i, i + articlesPerGroup)
                });
            }

            // Append instead of replacing
            setArticleGroups(prev => [...prev, ...grouped]);
        }
    }, [categoryArticles]);

    return (
        <div className="page page--read">
            <div className="page__heading-wrapper">
                <h1>{categoryInfo?.Category.Title ? categoryInfo.Category.Title : "Latest In..."}</h1>
                <ButtonPill label="All Articles" className="" link={RoutePaths.BrowseArticles} />
            </div>

            {!IsAuthenticated(userData) && (
                <ApiPromoPopUp
                    canBeDismissed={true}
                    apiEnum={PopUpType.Read}
                    isFullScreen={true}
                />
            )}

            <ArticlesGrid
                articleGroups={articleGroups}
                disableLoadMore={false}
                isLoading={loading}
                loadMoreUpdate={handleLoadMore}
            />
        </div>
    );
}

export default ArticleCategoryScreen;
