import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";

// Models
import { ISlideFeaturedContent } from "../../../../Models/Interfaces/ISlideFeaturedContent";
import {IUserDetails} from "../../../../Models/Interfaces/IUserDetails";

// Components
import ButtonPill from "../../../Buttons/ButtonPill";
import ButtonsPrevNext from "../../../Buttons/ButtonsPrevNext";

// Assets
import Divine from '../../../../Assets/Images/Browse/Divine Intervention Featured.png';
import PNG from '../../../../Assets/Images/Browse/PnG_Featured Header_2.png'
import CCAC from '../../../../Assets/Images/Browse/CCAC_Featured Image [odged].jpg'
import AstroVision from '../../../../Assets/Images/Browse/AstroVision_Featured Image [odged].jpg';

// Constants
import {RoutePaths} from "../../../../Constants/RoutePaths";

// Helpers
import {debounce} from "../../../../Helpers/Performance";
import {mergeRefs} from "../../../../Helpers/Refs";

// Hooks
import useFetchVideoById from "../../../../Hooks/Content/Watch/useFetchVideoById";

type SliderFeaturedContentProps = {
    isDarkMode: boolean;
    slides?: ISlideFeaturedContent[];
    classNameInner?: string;
    userDetails: IUserDetails;
};

const
    BREAKPOINT_SMALL_TABLET = 584,
    BREAKPOINT_TABLET = 760,
    BREAKPOINT_WIDE = 1272,
    BREAKPOINT_SUPER_WIDE = 1840,
    BREAKPOINT_MAX = 1900,
    BREAKPOINTS = [
        { name: "mobile", value: 0 },
        { name: "small-tablet", value: BREAKPOINT_SMALL_TABLET },
        { name: "tablet", value: BREAKPOINT_TABLET },
        { name: "wide", value: BREAKPOINT_WIDE },
        { name: "super-wide", value: BREAKPOINT_SUPER_WIDE },
        { name: "max", value: BREAKPOINT_MAX },
    ];

const SliderFeaturedContent: React.FC<SliderFeaturedContentProps> = (
    {
        slides = dummySlides,
        isDarkMode,
        classNameInner,
        userDetails
    }
) => {
    const
        [currentSlide, setCurrentSlide] = useState(0),
        [timeLeft, setTimeLeft] = useState(10000),
        slideIntervalRef = useRef<NodeJS.Timeout | null>(null),
        timerRef = useRef<NodeJS.Timeout | null>(null),
        sliderTimer = 10000,
        radius = 9,
        circumference = 2 * Math.PI * radius,
        progress = (timeLeft / sliderTimer) * circumference,

        // User Info
        userId = userDetails.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6",
        countryCode = !userDetails.CountryCode || userDetails.CountryCode === ""
            ? "GB"
            : userDetails.CountryCode,

        // Breakpoint state
        [breakpointClasses, setBreakpointClasses] = useState("is-breakpoint-mobile"),
        containerRef = useRef<HTMLDivElement>(null),

        resetSlideInterval = () => {
            if (slideIntervalRef.current) {
                clearInterval(slideIntervalRef.current);
            }

            if (timerRef.current) {
                clearInterval(timerRef.current);
            }

            setTimeLeft(sliderTimer); // Reset timer to 10 seconds

            slideIntervalRef.current = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
                setTimeLeft(sliderTimer); // Reset timer to 10 seconds
            }, sliderTimer); // Change slide every 10 seconds
            timerRef.current = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 100);
            }, 100); // Update timer every 100ms
        },

        handleSlideChange = (slideIndex: number) => {
            setCurrentSlide(slideIndex);
            resetSlideInterval();
        },

        handleSwipeLeft = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleSwipeRight = () => {
            setCurrentSlide((prevSlide) => (prevSlide - 1 + (slides?.length || 1)) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleOnClickNext = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides?.length || 1));
            resetSlideInterval();
        },

        handleOnClickPrev = () => {
            setCurrentSlide((prevSlide) => (prevSlide - 1 + (slides?.length || 1)) % (slides?.length || 1));
            resetSlideInterval();
        },

        swipeHandlers = useSwipeable({
            onSwipedLeft: handleSwipeLeft,
            onSwipedRight: handleSwipeRight,
        }),

        { video, videoLoading } = useFetchVideoById(3070, countryCode),
        now = new Date(),
        releaseDate = video?.Datetime ? new Date(video.Datetime + "Z") : null,
        earlyAccessDate = video?.DatetimeEarlyAccess ? new Date(video.DatetimeEarlyAccess + "Z") : null,
        userHasEarlyAccess = userDetails.AccessLevel >= 6,
        isEarlyAccessWindow = earlyAccessDate && earlyAccessDate <= now && releaseDate && releaseDate > now,
        isNotReleased = releaseDate && releaseDate > now,

        slidesWithDynamicButtonText = slides?.map(slide => {
            if (slide.buttonLink === RoutePaths.Watch(3070)) {
                let buttonText = slide.buttonText;

                if (videoLoading) {
                    buttonText = "Loading...";

                } else if (isNotReleased) {
                    if (userHasEarlyAccess) {
                        buttonText = isEarlyAccessWindow ? "Early Access" : `Coming ${earlyAccessDate && earlyAccessDate.toLocaleDateString("en-GB")}`;
                    } else {
                        buttonText = releaseDate ? `Releases at ${releaseDate.toLocaleDateString("en-GB")}` : "Coming Soon";
                    }
                } else {
                    buttonText = "Watch Now"
                }

                return { ...slide, buttonText, useEarlyAccessStyles: userHasEarlyAccess && isEarlyAccessWindow };
            }
            return slide;
        });

    useEffect(() => {
        resetSlideInterval();
        return () => {
            if (slideIntervalRef.current) {
                clearInterval(slideIntervalRef.current);
            }

            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [slides]);

    // Resize observer with fallback for older browsers
    useEffect(() => {
        resetSlideInterval();
        return () => {
            if (slideIntervalRef.current) clearInterval(slideIntervalRef.current);
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, [slides]);

    useEffect(() => {
        const node = containerRef.current;
        if (!node) return;
        const updateBreakpoint = () => {
            const width = node.clientWidth;
            if (width) {
                const classes = BREAKPOINTS.filter(b => width >= b.value).map(b => `is-breakpoint-${b.name}`).join(" ");
                setBreakpointClasses(classes);
            }
        };
        updateBreakpoint();
        const debouncedUpdate = debounce(updateBreakpoint, 200);
        if (typeof ResizeObserver !== "undefined") {
            const resizeObserver = new ResizeObserver(() => debouncedUpdate());
            resizeObserver.observe(node);
            return () => resizeObserver.disconnect();
        } else {
            window.addEventListener("resize", debouncedUpdate);
            return () => window.removeEventListener("resize", debouncedUpdate);
        }
    }, []);

    // useEffect(() => {
    //     const abortController = new AbortController;
    //     GetSlider(1, abortController);
    // }, []);

    return (
        <div
            {...swipeHandlers}
            // @ts-ignore
            ref={mergeRefs(containerRef, swipeHandlers.ref)}
            className={`layout--slider-featured-content ${isDarkMode ? "is-dark-mode" : "has-light-mode"} ${breakpointClasses}`}
        >
            <div className={`slider-featured-content__inner ${classNameInner ?? ""}`} style={{
                transform: `translateX(-${currentSlide * (100 / (slides?.length || 1))}%)`,
                display: "flex",
                width: `${(slides?.length || 1) * 100}%`
            }}>
                {slidesWithDynamicButtonText && slidesWithDynamicButtonText.map((slide, index) => (
                    <div key={index}
                         className={`slider-featured-content__slide u-relative_hidden ${currentSlide === index ? "is-active" : "is-not-active"}`}
                         style={{background: "#000000", width: `${100 / (slides?.length || 1)}%`, flexShrink: 0}}>
                        <div className="u-full_cover_absolute"
                             style={{background: `url("${slide.bgImage}") center/cover no-repeat`, opacity: "0.5"}}/>
                        <div className="slider-featured-content__content">
                            <h1><span>{slide.heading}</span></h1>
                            <h2><span>{slide.subHeading}</span></h2>
                            <div className="slider-featured-content__button-wrapper u-relative_hidden">
                                <ButtonPill label={slide.buttonText} className={slide.useEarlyAccessStyles ? 'has-early-access' : 'has-normal-access'} link={slide.buttonLink ?? ""}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {slides && (
                <div className="slider-featured-content__dots">
                    {slides.map((_, index) => (
                        <div key={index} onClick={() => handleSlideChange(index)}
                             className={`slider-featured-content__dot-control ${currentSlide === index ? "is-active" : "is-not-active"}`}/>
                    ))}
                </div>
            )}

            <ButtonsPrevNext
                onPrev={handleOnClickPrev}
                onNext={handleOnClickNext}
                currentIndex={currentSlide}
                parentClass="slider-featured-content__controls"
            />

            <svg className="slider-featured-content__radial-timer" width="22" height="22" viewBox="0 0 22 22">
                <circle stroke="white" fill="transparent" strokeWidth="4" strokeDasharray={circumference}
                        strokeDashoffset={circumference - progress} r={9} cx="11" cy="11"/>
            </svg>

            <div className="slider-featured-content__shadow u-full_cover_absolute"/>
        </div>
    );
};

const dummySlides = [
    {
        heading: "Persona Non Grata: The Demonisation of David Icke",
        subHeading: "Banned from Europe and Australia, Persona Non Grata dives into David Icke's quest for truth in an era of escalating censorship.",
        bgImage: PNG,
        buttonText: 'Watch Now',
        buttonLink: RoutePaths.Watch(3070),
        useEarlyAccessStyles: false
    },
    {
        heading: "AstroVision",
        subHeading: "Explore iconic films and TV series through a cosmic lens as Zakariya Adeel uncovers the astrological meanings behind your favorite narratives.",
        bgImage: AstroVision,
        buttonText: "Watch Now",
        buttonLink: RoutePaths.SeriesEpisodes(190),
        useEarlyAccessStyles: false
    },
    {
        heading: "Cults, Conspiracies & Chaos",
        subHeading: "Expose the global web connecting secret societies, dark cults, and elite conspiracies orchestrating humanity's shift toward a dystopian transhumanist future.",
        bgImage: CCAC,
        buttonText: 'Watch Now',
        buttonLink: RoutePaths.SeriesEpisodes(189),
        useEarlyAccessStyles: false
    },
    {
        heading: "Divine Intervention",
        subHeading: "Discover how unseen extraterrestrial forces have guided our evolution—and how we can reclaim our sovereign destiny.",
        bgImage: Divine,
        buttonText: 'Watch Now',
        buttonLink: RoutePaths.Watch(1026),
        useEarlyAccessStyles: false
    }
];

export default SliderFeaturedContent;
