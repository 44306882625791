import React from "react";

// Models
import IPlanDTO from "../../../Models/DTOs/IPlanDTO";
import ButtonPill from "../../Buttons/ButtonPill";
import { RoutePaths } from "../../../Constants/RoutePaths";
import {NavLink} from "react-router-dom";

interface Props {
    plans: IPlanDTO[];
}

const HorizontalSubscribeCTA: React.FC<Props> = ({ plans }) => {
    if (!plans || plans.length === 0) return null;

    const
        hasPromotion = plans.length >= 3,
        promoPlan = hasPromotion ? plans.find(p => p.IsPromotion) : null,
        monthlyPlan = plans.find(p => p.Title === "Truth Seeker"),
        yearlyPlan = plans.find(p => p.Title === "Game Changer");

    const renderPlan = (plan: IPlanDTO) => {
        const
            fullPhase = plan.Phases[1],
            introPhase = plan.Phases[0],
            currencySymbol = plan.Symbol === "&#163;" ? "£" : plan.Symbol,
            isMonthly = plan.Period === 0,
            timeLabel = isMonthly ? "First Month:" : "First Year:",
            isPromo = plan.IsPromotion,
            discountPercent = Math.round(((fullPhase.Price - introPhase.Price) / fullPhase.Price) * 100);

        return (
            <>
                {isPromo && (
                    <p className="horizontal-sub-cta__promo-title">
                        {plan.Title}
                    </p>
                )}

                <NavLink
                    to={`${RoutePaths.SignUp[0]}?plan=${plan.Title.replace(/\s+/g, "-")}`}
                    className={`horizontal-sub-cta__plan ${isPromo ? 'is-promo' : 'is-normal-pricing'}`}
                    key={plan.PlanId}
                >
                    <p className="horizontal-sub-cta__cta">
                        <span className="horizontal-sub-cta__percent-discount">-{discountPercent}%</span>
                        <span className="horizontal-sub-cta__period">{timeLabel}</span>
                        <span className="horizontal-sub-cta__prices">{`${currencySymbol}${introPhase.Price.toFixed(0)}`}</span>
                    </p>
                </NavLink>
            </>
        );
    };


    return (
        <div className={`horizontal-sub-cta ${hasPromotion ? 'has-promo' : 'has-normal-pricing'}`}>
            <h2>Unlock the Full Experience:</h2>

            <div className="horizontal-sub-cta__plans-wrapper">
                {hasPromotion && promoPlan && renderPlan(promoPlan)}
                {!hasPromotion && monthlyPlan && renderPlan(monthlyPlan)}
                {!hasPromotion && (<p className="horizontal-sub-cta__plan-divider">OR</p>)}
                {!hasPromotion && yearlyPlan && renderPlan(yearlyPlan)}

                {/** Debug Only **/}
                {/*{monthlyPlan && renderPlan(monthlyPlan)}*/}
                {/*<p className="horizontal-sub-cta__plan-divider">OR</p>*/}
                {/*{yearlyPlan && renderPlan(yearlyPlan)}*/}
            </div>
        </div>
    );
};

export default HorizontalSubscribeCTA;
